import React from 'react'

const Payment = ({total}) => {
  return (
    <div className='flex flex-col w-full p-4'>
                <div className="flex items-center">
          <img className="w-6 mr-3" src="/img/icon-offers-256x256.png" alt="" />
          <span className="text-lg font-semibold text-white uppercase">Review & Pay</span>
        </div>
        <div className="flex justify-between w-full py-2 my-2 border-t border-b border-dashed border-neutral-700">
            <span className='text-lg font-semibold text-white uppercase'>
            Amount Due
            </span>
            <span className='text-lg font-semibold text-white uppercase'>
            {total.toFixed(2)} AED
            </span>
        </div>
        <div className="flex items-center w-full">
            <div className="flex items-center justify-center w-6 h-6 border rounded-full border-neutral-700">
                <div className="w-2 h-2 bg-white rounded-full"></div>
            </div>
            <span className='ml-2 font-semibold text-white'>Credit and Debit cards</span>
        </div>
    </div>
  )
}

export default Payment