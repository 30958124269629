import React from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {
  return (
    <Link to={'/'} className='flex justify-center w-full p-4 bg-black'>
        <img className='w-28' src="/img/logo-dark-288x92.avif" alt="" />

    </Link>
  )
}

export default NavBar