import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import combinedData from "../../assets/combined.json" // Import the combined JSON data
import times from "../../assets/times.json"
import Banner from "../../components/WhatsOn/Banner"
import dayjs from "dayjs";

const Movie = () => {

  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    // Generate an array of dates for today and the next 4 days
    const today = dayjs();
    const days = Array.from({ length: 10 }, (_, i) => today.add(i, "day"));
    setDates(days);
  }, []);

  const formatDateLabel = (date, index) => {
    const today = dayjs();
    if (index === 0) return "Today";
    if (index === 1) return "Tomorrow";
    return date.format("ddd D MMM");
  };

  const handleDateClick = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
  };
  
  const { name } = useParams()
  const [selected, setSelected] = useState("Today");
  const getFormattedDate = (dayOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset); // Add day offset (1 for tomorrow)
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long'
    });
  };
  const dateToShow = selected === "Tomorrow" ? getFormattedDate(1) : getFormattedDate();
  const [movie, setMovie] = useState(null)

  useEffect(() => {
    // Find the movie with a title that matches the name parameter
    const foundMovie = combinedData.find((movie) => movie.title === name)
    setMovie(foundMovie) // Set the found movie to the state
  }, [name])

  if (!movie) {
    return <p>Movie not found</p> // Show this if no movie matches
  }
  if (!times) {
    return <div>Loading...</div> // or some other fallback UI
  }

  const malls = Object.keys(times) // Now `times` will be safely checked

  const classification = movie.classification
  const rating = classification.match(/[A-Za-z]+/g)[0] // Extracts "PG"
  const age = classification.match(/\d+/g)[0] // Extracts "13"

  return (
    <div className="flex flex-col">
      <Banner />
      <div className="flex flex-col p-4">
      <div className="relative w-full pt-1 mt-4">
        <div className="absolute top-0 w-14 h-[3px] bg-[#009ddb]"></div>
        <span className="text-2xl font-bold text-white uppercase">{name}</span>
      </div>
      <div className="flex justify-between w-full my-3">
        <div className="flex flex-col w-8 h-8 border-2 border-white rounded-full">
          <span className="text-[10px] leading-3 font-semibold text-center text-white">
            {rating}
          </span>
          <span className="text-[10px] leading-3 font-semibold text-center text-white">
            {age}
          </span>
        </div>
      </div>
      <div className="w-full">
        <iframe
          className="w-full h-52"
          src={movie.trailerUrl}
          frameborder="0"
          allowfullscreen="true"
          allowtransparency="true"
          data-ot-ignore=""
          data-gtm-yt-inspected-1888546_1219="true"
          id="141147530"
          data-gtm-yt-inspected-1888546_1141="true"
          data-gtm-yt-inspected-123="true"
          title="PIECE BY PIECE - Official Trailer [HD] - Only In Theaters October 11"
        ></iframe>
      </div>
      <a
        href="#showtimes"
        className="bg-[#d40f7d] mt-4 w-full py-2 text-sm text-white rounded flex justify-center"
      >
        View Showtimes
      </a>

      <div className="w-full my-4 border-b-2 border-dashed border-neutral-700"></div>

      <div className="flex flex-col text-sm font-semibold text-white gap-y-4">
        <span className="">Genre: {movie.genre}</span>
        <span className="">Running Time: {movie.running_time}</span>
        <span className="">Release Date: {movie.release_date}</span>
        <span className="">Starring: {movie.starring}</span>
        <span className="">Language: {movie.language}</span>
        <span className="">Subtitle(s): {movie.subtitle}</span>
        <span className="">{movie.description}</span>
      </div>

      <div className="w-full my-4 border-b-2 border-dashed border-neutral-700"></div>
      <section
        id="showtimes"
        className=""
      >
        <div className="relative w-full pt-1">
          <div className="absolute top-0 w-14 h-[3px] bg-[#009ddb]"></div>
          <span className="text-lg font-bold text-white uppercase">
            {name} - Showtimes
          </span>
        </div>
        <div className="flex w-full p-2 overflow-x-scroll text-sm border-t border-b gap-x-4 border-neutral-700">

        {dates.map((date, index) => (
        <div
          key={index}
          onClick={() => handleDateClick(date)}
          className={`cursor-pointer flex-shrink-0 hover:text-[#009ddb] ${
            selectedDate === date.format("YYYY-MM-DD")
              ? "text-[#009ddb] font-semibold"
              : "text-[#5C5D5F]"
          }`}
        >
          {formatDateLabel(date, index)}
        </div>
      ))}
        </div>

        <div>
          <div className="flex flex-col text-white">
            {malls.map((mall) => (
              <div className="my-4" key={mall}>
                        <div className="relative w-full pt-1">
          <div className="absolute top-0 w-14 h-[3px] bg-[#009ddb]"></div>
          <span className="text-lg font-bold text-white uppercase">
            {mall}
          </span>
        </div>
                {times[mall].map((showtime, index) => (
                  <div className="mt-2 " key={index}>
                    <h3 className="mb-1 text-sm font-semibold" >{showtime.category}</h3>
                    <div className="flex gap-2 flex-wrap">

                    {showtime.showtimes.map((showtimeDetails, idx) => (
                      <div className="bg-[#d40f7d] rounded px-2 py-1" key={idx}>
                        <Link to={`/book/${name}/${showtimeDetails.time}/${mall}/${selectedDate}`} className="text-xs font-semibold">{showtimeDetails.time}</Link>
                      </div>
                    ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
    </div>
  )
}

export default Movie
