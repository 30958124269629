import React, { useState } from "react"
import meals from "../../assets/meals.json"
import { FaMinus, FaPlus } from "react-icons/fa"
import { useCart } from "react-use-cart"
import trackUserAction from "../../providers/track_action"

const Meals = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(meals)[0]
  ) // Default to first category
  const [expandedProduct, setExpandedProduct] = useState(null) // Track expanded product
  const { addItem, updateItemQuantity, items } = useCart()
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value)
    setExpandedProduct(null) // Reset expanded product when category chsanges
  }

  const toggleProductDetails = (productName) => {
    setExpandedProduct(expandedProduct === productName ? null : productName) // Toggle product details visibility
  }

  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col w-full py-2 border-t border-b border-neutral-700">
        <div className="flex items-center">
          <img
            className="w-6 mr-3"
            src="/img/icon-offers-256x256.png"
            alt=""
          />
          <span className="text-lg font-semibold text-white">UPGRADES</span>
        </div>
        {/* Category Dropdown */}
        <div className="mt-4">
          <select
            className="w-full p-4 text-white rounded bg-neutral-800"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            {Object.keys(meals).map((category) => (
              <option
                key={category}
                value={category}
                className="text-whtie"
              >
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex flex-col w-full">
        {/* Products */}
        {selectedCategory && (
          <div className="mt-4">
            {meals[selectedCategory].map((product) => {
              const productId = product.id || product.name // Use product.id or product.name as a unique identifier
              const cartItem = items.find((item) => item.id === productId)

              return (
                <div
                  key={productId}
                  className="my-2"
                >
                  <div
                    className="relative flex items-center justify-between border cursor-pointer border-neutral-700"
                    onClick={() => toggleProductDetails(product.name)}
                  >
                    <div className="flex flex-col p-4 text-sm font-semibold">
                      <span className="leading-5 mb-2 text-[#009ddb]">
                        {product.name}
                      </span>
                      <span className="leading-5 text-white line-through terxt-xs font-regular">
                        {(product.price).toFixed(2)} AED
                      </span>
                      <span className="leading-5 text-white">
                        {(product.price/2).toFixed(2)} AED
                      </span>
                    </div>

                    {/* Decrement button */}
                    {cartItem && cartItem.quantity > 0 ? (
                      <div className="absolute bottom-0 right-0 flex items-center">
                        <div className="flex items-center justify-between w-24 overflow-hidden bg-white">
                          <button
                            className="bg-[#d40f7d] flex-shrink-0 w-6 h-6 flex items-center justify-center  mr-1"
                            onClick={(e) => {

                              e.stopPropagation()
                              updateItemQuantity(
                                productId,
                                cartItem.quantity - 1
                              )
                              
                            }}
                          >
                            <FaMinus color="white" />
                          </button>
                          <span className="flex-shrink-0 mx-2 font-semibold text-neutral-700">
                            {cartItem.quantity}
                          </span>
                          <button
                            className="bg-[#d40f7d] flex-shrink-0 w-6 h-6 flex items-center justify-center "
                            onClick={(e) => {
                              e.stopPropagation()
                              if (cartItem) {
                                updateItemQuantity(
                                  productId,
                                  cartItem.quantity + 1
                                )
                              } else {
                                addItem({ ...product, id: productId })
                                
                              }
                            }}
                          >
                            <FaPlus color="white" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="absolute flex items-center bottom-1 right-1">
                        <button
                          className="bg-[#d40f7d] w-6 h-6 flex items-center justify-center rounded-full"
                          onClick={(e) => {
                            e.stopPropagation()
                            if (cartItem) {
                              updateItemQuantity(
                                productId,
                                cartItem.quantity + 1
                              )
                            } else {
                              addItem({ ...product, id: productId })
                            }
                          }}
                        >
                          <FaPlus color="white" />
                        </button>
                      </div>
                    )}
                    {/* Increment/Add button */}

                    <img
                      className="flex-shrink-0 object-cover w-24 h-24"
                      src={product.imageUrl}
                      alt=""
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Meals
