import React from 'react'
import WhatsOn from '../../components/WhatsOn'
import MoviesList from '../../components/WhatsOn/Movies/MoviesList'
import Banner from '../../components/WhatsOn/Banner'
import trackUserAction from '../../providers/track_action'

const Main = () => {
  trackUserAction('User landed on page',false)
  return (
    <div>
      <Banner />
        <WhatsOn />
        <MoviesList />
    </div>
  )
}

export default Main