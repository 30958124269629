import movies from '../../../assets/combined.json'
import MovieCard from './MovieCard'

const MoviesList = () => {
  return (
    <div className='grid w-full grid-cols-2 gap-2 p-4'>{movies.map((movie)=>(
        <MovieCard movie={movie} />
    ))}</div>
  )
}

export default MoviesList