import React from 'react'
import { Link } from 'react-router-dom'
import trackUserAction from '../../../providers/track_action'

const MovieCard = ({movie}) => {
  return (
    <Link onClick={()=>{    trackUserAction(`User Clicked On ${movie.title} Movie`,false)}} to={`/movies/${movie.title}`} className="flex flex-col mb-6">
        <img src={`${movie.poster}`} alt="" />    
        <span className='text-lg font-semibold text-white '>
            {movie.title}
        </span>
        <div className="flex items-center justify-center w-8 h-8 my-2 border-2 border-white rounded-full">
            <span className='text-[8px] font-semibold text-white'>{movie.classification}</span>
        </div>
        <span className='text-sm font-semibold text-white'>Language: {movie.language}</span>
        <div className="flex rounded bg-[#d40f7d] py-2 mt-4 items-center justify-center w-full">
            <span className='text-sm text-white'>Showtimes</span>
        </div>
    </Link>
  )
}

export default MovieCard