import React, { useState } from "react"
import trackUserAction from "../../providers/track_action"

const SeatSelector = ({ totalPrice, setTotalPrice }) => {
  const seatGroups = [
    { name: "Regular", rows: 4, columns: 9, price: 50 },
    { name: "Regular", rows: 4, columns: 9, price: 50 },
    { name: "Premium", rows: 3, columns: 7, price: 65 },
    { name: "Premium", rows: 3, columns: 7, price: 65 },
    { name: "Preferred View", rows: 4, columns: 7, price: 70 },
    { name: "Preferred View", rows: 4, columns: 7, price: 70 },
  ]

  // Function to generate initial seats with the possibility to mark some as unavailable
  const generateSeats = (rows, columns, unavailableSeats = []) => {
    const seats = Array.from({ length: rows }, () =>
      Array.from({ length: columns }, () => "available")
    )

    // Mark specified seats as unavailable
    unavailableSeats.forEach(([rowIndex, colIndex]) => {
      if (seats[rowIndex] && seats[rowIndex][colIndex] !== undefined) {
        seats[rowIndex][colIndex] = "unavailable"
      }
    })

    return seats
  }

  // State holding the seats for each group
  const [seats, setSeats] = useState(
    seatGroups.reduce((acc, group, index) => {
      const groupName = `${group.name}-${index}`
      // Example: Marking some seats as unavailable for demonstration
      const unavailableSeats = [
        [0, 0],
        [1, 1], // Regular seat group example
        [0, 5],
        [2, 4], // Premium seat group example
        [3, 6],
        [2, 1], // Preferred View seat group example
      ]
      acc[groupName] = generateSeats(
        group.rows,
        group.columns,
        unavailableSeats
      )
      return acc
    }, {})
  )

  // Toggle seat status (select/deselect)
  const toggleSeat = (groupName, rowIndex, colIndex, price) => {
    trackUserAction('User chose seat',false)
    setSeats((prevSeats) => {
      // Deep copy of the state to avoid mutating the original state
      const newSeats = JSON.parse(JSON.stringify(prevSeats)) // Deep copy of the state

      const groupSeats = newSeats[groupName]
      const seatStatus = groupSeats[rowIndex][colIndex]

      // Update total price based on seat selection
      setTotalPrice((prevTotal) => {
        if (seatStatus === "available") {
          // Seat is being selected, add the price
          return prevTotal + price
        } else {
          // Seat is being deselected, subtract the price
          return prevTotal - price
        }
      })

      // Toggle the seat between 'available' and 'selected'
      groupSeats[rowIndex][colIndex] =
        seatStatus === "available" ? "selected" : "available"

      return newSeats
    })
  }

  // Get the Tailwind class based on seat status
  const getSeatClass = (status, groupName) => {
    switch (status) {
      case "unavailable":
        return "bg-gray-500 cursor-not-allowed" // Gray color for unavailable seats
      case "selected":
        return "bg-pink-500 cursor-pointer"
      case "preferred":
        return "bg-green-500"
      case "premium":
        return "bg-yellow-500"
      case "regular":
        return "bg-blue-500"
      default:
        if (groupName === "Preferred View") {
          return "bg-green-500" // Preferred View group gets a unique color
        } else if (groupName === "Premium") {
          return "bg-yellow-500" // Premium group gets a unique color
        } else {
          return "bg-[#2bace1]" // Default blue color for regular seats
        }
    }
  }

  return (
    <div className="p-4">
      <div className="text-white border-dashed border-neutral-700">
        <div className="relative flex items-center justify-center w-full mb-4">
          <img
            className="w-3/4"
            src="/img/seats-screen-dark@2x.png"
            alt=""
          />
          <span className="absolute text-white">Screen</span>
        </div>
        <div className="grid justify-center grid-cols-2 gap-x-4">
          {seatGroups.map((group, index) => {
            const uniqueGroupName = `${group.name}-${index}` // Unique key for each group
            return (
              <div
                key={uniqueGroupName} // Unique key for each group
                className={`${
                  index % 2 === 0 ? "justify-self-end" : "justify-self-start "
                }`}
              >
                <div className="justify-center mb-4 w-fit">
                  {seats[uniqueGroupName].map((row, rowIndex) => (
                    <div
                      key={rowIndex}
                      className="mb-[2px] flex w-fit"
                    >
                      {row.map((seat, colIndex) => (
                        <button
                          key={colIndex}
                          onClick={() =>
                            toggleSeat(
                              uniqueGroupName,
                              rowIndex,
                              colIndex,
                              group.price
                            )
                          }
                          className={`w-[14px] h-[14px] rounded-[2px] ${getSeatClass(
                            seat,
                            group.name
                          )} mx-[2px]`}
                          disabled={seat === "unavailable"} // Disable unavailable seats
                        ></button>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
        <div className="flex flex-col text-sm">
          <div className="flex justify-center gap-x-2">
            <div className="flex items-center flex-shrink-0">
              <div className="w-4 h-4 mr-2 bg-gray-500 rounded"></div>
              <span>Unavailable</span>
            </div>
            <div className="flex items-center flex-shrink-0">
              <div className="w-4 h-4 mr-2 bg-pink-500 rounded"></div>
              <span>Your Seat</span>
            </div>
            <div className="flex items-center flex-shrink-0">
              <div className="w-4 h-4 mr-2 bg-green-500 rounded"></div>
              <span>Preferred View</span>
            </div>
          </div>
          <div className="flex justify-center mt-2 gap-x-2">
          <div className="flex items-center flex-shrink-0">
            <div className="w-4 h-4 mr-2 bg-yellow-500 rounded"></div>
            <span>Premium</span>
          </div>
          <div className="flex items-center flex-shrink-0">
            <div className="w-4 h-4 mr-2 bg-blue-500 rounded"></div>
            <span>Regular</span>
          </div>
          </div>
        </div>

        <div className="flex justify-center gap-4 mt-4 overflow-x-scroll ">

        </div>
      </div>
    </div>
  )
}

export default SeatSelector
