import React from 'react'

const WhatsOn = () => {
  return (
    <div className='flex flex-col p-4'>

        <div className="relative w-full pt-1">
            <div className="absolute top-0 w-14 h-[3px] bg-[#009ddb]"></div>
            <span className='text-2xl font-bold text-white uppercase'>what's on</span>
        </div>
        <div className="w-full mt-4 border-b-2 border-dashed border-neutral-700"></div>
    </div>
  )
}

export default WhatsOn