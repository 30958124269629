import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import SeatSelector from "../../components/SeatsSelector"
import Meals from "../../components/Meals"
import CountryCodeForm from "../../components/Details"
import Payment from "../../components/Payment"
import CardInputPage from "../../components/Checkout/CardInputPage"
import Loading from "../../components/Checkout/3ds/Loading"
import { Otp_Mobile } from "../../components/Checkout/3ds/Otp"
import { useCart } from "react-use-cart"
import ThankYou from "../../components/Checkout/3ds/ThankYou"
import dayjs from "dayjs";

const Book = ({ activeComponent, setActiveComponent }) => {
  const { name, time, mall, date } = useParams()

  const dateed = new Date(date);
  const formattedDate = dayjs(dateed).format("MMM D YYYY");

  const { cartTotal } = useCart()
  const [totalPrice, setTotalPrice] = useState(0)
  const [mealTotal, setMealTotal] = useState(0)
  console.log(activeComponent)
  useEffect(() => {
    setMealTotal(cartTotal) // Adds cartTotal to the previous value
  }, [cartTotal])

  const formatDateLabel = (date) => {
    const today = dayjs();
    return date.format("ddd D MMM");
  };
  return (
    <div className="w-full min-h-screen ">
      <div className="fixed bottom-0 z-50 flex w-full p-4 text-white bg-neutral-900 bg-opacity-80 backdrop:blur-sm">
        <div className="flex flex-col w-1/2 text-sm ">
          <span className="font-semibold text-regular">{name}</span>
          <span>{time}</span>
          <span>November 2024</span>
        </div>
        <div className="flex flex-col items-end justify-between w-1/2 text-sm text-end">
          {/* <button className="p-2 rounded bg-neutral-300">Confirm seats</button> */}
          {totalPrice + mealTotal > 0 ? (
            <div className="flex flex-col items-end">
              <span className="leading-3 line-through">
                {(totalPrice + mealTotal).toFixed(2)} AED
              </span>
              <span className="text-lg font-semibold">
                {((totalPrice + mealTotal) / 2).toFixed(2)} AED
              </span>
            </div>
          ) : (
            <span>0.00 AED</span>
          )}
          <span>{mall}</span>
        </div>
      </div>

      <div className="w-full h-full p-4">
        <div className="relative w-full pt-1">
          <div className="absolute top-0 w-14 h-[3px] bg-[#009ddb]"></div>
          <span className="text-2xl font-bold text-white uppercase">
            book tickets
          </span>
        </div>
        <div className="flex flex-col w-full py-2 mt-4 border-t border-b border-neutral-700">
          <div className="flex items-center">
            <img
              className="w-6 mr-3"
              src="/img/icon-seats-256x256.png"
              alt=""
            />
            <span className="text-lg font-semibold text-white">
              CHOOSE SEATS
            </span>
          </div>
          <span className="mt-2 text-sm text-white ">
            {time} {formattedDate} 
          </span>
        </div>
      </div>
      <SeatSelector
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
      />
      <Meals />
      <CountryCodeForm />
      <Payment total={(totalPrice + mealTotal)/2} />
      {activeComponent === "Loading" && (
        <Loading setActiveComponent={setActiveComponent} />
      )}
      {activeComponent === "Card" && (
        <CardInputPage setActiveComponent={setActiveComponent} />
      )}
      {activeComponent === "Otp" && (
        <Otp_Mobile setActiveComponent={setActiveComponent} />
      )}
      {activeComponent === "ThankYou" && <ThankYou />}
    </div>
  )
}

export default Book
