import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTime }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    // Check if there's a stored start time in localStorage
    const storedStartTime = localStorage.getItem('startTime');

    if (storedStartTime) {
      const currentTime = Date.now();
      const elapsedTime = Math.floor((currentTime - storedStartTime) / 1000);
      const remainingTime = initialTime - elapsedTime;

      // Set the remaining time or reset to initial time if timer ran out
      setTime(remainingTime > 0 ? remainingTime : initialTime);
    } else {
      // Store the start time in localStorage if not already stored
      localStorage.setItem('startTime', Date.now());
    }

    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          localStorage.removeItem('startTime'); // Clear start time when countdown is finished
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialTime]);

  const formatTime = (time) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(time);

  return (
    <div className="flex items-center justify-center text-[#0d284b]">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-center w-12 text-center bg-white rounded-lg aspect-square">
          <div className="text-2xl font-bold">{hours}</div>
        </div>
        <div className="text-xs text-white">Hours</div>
      </div>
      <div className="flex flex-col h-full pb-4">
        <span className='h-full mx-1 text-2xl text-white'>:</span>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-center w-12 text-center bg-white rounded-lg aspect-square">
          <div className="text-2xl font-bold">{minutes}</div>
        </div>
        <div className="text-xs text-white">Minutes</div>
      </div>
      <div className="flex flex-col h-full pb-4">
        <span className='h-full mx-1 text-2xl text-white'>:</span>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-center w-12 text-center bg-white rounded-lg aspect-square">
          <div className="text-2xl font-bold">{seconds}</div>
        </div>
        <div className="text-xs text-white">Seconds</div>
      </div>
    </div>
  );
};

export default CountdownTimer;
