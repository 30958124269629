import React from 'react'
import CountdownTimer from './CountDown'

const Banner = () => {
    const initialTimeInSeconds = 3 * 3600 + 12 * 60 + 47

  return (
    <div className="relative w-full">
    <img src="/banner.png" alt="" />
    <div className="absolute left-2 bottom-2">
    <CountdownTimer initialTime={initialTimeInSeconds} />
    </div>
  </div>
  )
}

export default Banner