import React from "react"
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa"

const Footer = () => {
  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col w-full p-4 bg-[#1B1B1D]">
        <img
          className="w-20"
          src="/img/logo-dark-288x92.avif"
          alt=""
        />
        <span className="font-semibold text-white uppercase">
          STay in touch
        </span>
        <div className="w-full flex gap-[10px]">
          <FaFacebookF color="#808080" />
          <FaTwitter color="#808080" />
          <FaInstagram color="#808080" />
          <FaYoutube color="#808080" />
        </div>
      </div>
      <div className="w-full bg-[#31261d] p-4">
        <img className="w-40" src="/img/logo-maf-dark@2x.png" alt="" />
      </div>
    </div>
  )
}

export default Footer
